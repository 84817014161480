import React from "react";
import trait from "../../assets/img/pagePersoTrace.svg";
import FunFact from "../FunFact/FunFact";

export default function PagePerso() {
  return (
    <div className="pagePerso">
      <div className="textAPropos">
        <p className="balooT">
          Gamer invétéré, streamer à mes heures perdues, cuistot depuis peu,
          passionné de sport de glisse, mais surtout papa épanoui de Léandre
          depuis un certain 31 décembre... je m’appelle Albéric Andreae et j’ai
          31 ans.{" "}
        </p>
      </div>
      <div className="titreFunFacts">
        <h4>
          Fun facts{" "}
          <span className="spanNameTrace">
            <img src={trait} className="traitTitre" />
            en chiffres :
          </span>
        </h4>
      </div>
      <div className="funFactsBlocs">
        <FunFact
          number="15"
          text={[
            "C’est le nombre de ",
            "déménagements ",
            "que j’ai pu faire en 30 ans. Pas mal non ?",
          ]}
          color="blue"
        />
        <FunFact
          number="2560"
          text={[
            "C’est le nombre d'",
            "heures de jeu ",
            "que j’ai à mon actif sur World of Warcraft",
          ]}
          color="pink"
        />
        <FunFact
          number="3214"
          text={[
            "C’est le nombre de ",
            "couches ",
            "que j’ai changé en moins de deux ans !",
          ]}
          color="purple"
        />
      </div>
    </div>
  );
}
