import React, { useState, useEffect, useRef, useContext } from "react";
import { NavLink } from "react-router-dom";
import Toggle from "react-styled-toggle";
import logo from "../../assets/img/logo_porfolio.svg";
import iGitHub from "../../assets/img/iGitHub.svg";
import iLinkedin from "../../assets/img/iLinkedin.svg";
import { DarkModeContext } from "../../context";

export default function NavBar() {
  const { darkMode, toggleDarkMode } = useContext(DarkModeContext);
  console.log(darkMode);
  const [isMobile, setIsMobile] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [hasBackground, setHasBackground] = useState(false);
  const menuRef = useRef(null);
  let colorButtonToggle = !darkMode ? "#fef6e4" : "#0e103d";

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuVisible(false);
      }
    };

    if (menuVisible) {
      window.addEventListener("click", handleOutsideClick);
    }

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [menuVisible]);
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      setHasBackground(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };
  return (
    <header>
      {isMobile && (
        <button
          className={`navBarBurger ${
            hasBackground && !menuVisible ? "hasBackground" : ""
          }`}
          onClick={toggleMenu}
          ref={menuRef}
        >
          <span className={`burgerBar ${menuVisible ? "open" : ""}`} />
        </button>
      )}
      <div className={`globalNav ${isMobile && !menuVisible ? "hidden" : ""}`}>
        <NavLink to="/" className={"logoLink"}>
          <img src={logo} alt="logo-albéric" className="logoPorfolio"></img>
        </NavLink>
        <nav className="navMenu">
          <ul className="ulNav">
            <li className="liNav">
              <NavLink to="/" className={"lienNav"}>
                <button className="navButton">Accueil</button>
              </NavLink>
            </li>
            <li className="liNav">
              <NavLink to="/about" className={"lienNav"}>
                <button className="navButton">À propos</button>
              </NavLink>
            </li>
            <li className="liNav">
              <NavLink to="/projects" className={"lienNav"}>
                <button className="navButton">Projets</button>
              </NavLink>
            </li>
            <li className="liNav">
              <NavLink to="/contact" className={"lienNav"}>
                <button className="navButton">Contact</button>
              </NavLink>
            </li>
          </ul>
        </nav>
        <div className="iconsRS">
          <Toggle
            sliderWidth={21}
            sliderHeight={21}
            width={50}
            height={27}
            translate={21}
            backgroundColorUnchecked="#0e103d"
            backgroundColorChecked="#fef6e4"
            backgroundColorButton={colorButtonToggle}
            onChange={toggleDarkMode}
            checked={darkMode}
          />
          <NavLink
            to="https://www.linkedin.com/in/alb%C3%A9ric-andreae-545886146/"
            target="_blank"
          >
            <img
              src={iLinkedin}
              alt="logo-Linkedin"
              className="iLinkedin"
            ></img>
          </NavLink>
          <NavLink to="https://github.com/MisterDougi" target="_blank">
            <img src={iGitHub} alt="logo-GitHub" className="iGithub"></img>
          </NavLink>
        </div>
        <p className="copyRight">
          © 2023 Albéric Andreae <br />{" "}
          <span className="portfolioVersion">v1.5.5</span>
        </p>
      </div>
    </header>
  );
}
