import React from "react";
import ContactForm from "../../components/ContactForm/ContactForm";
import traitContact from "../../assets/img/traceContactPage1.svg";

export default function Contact() {
  return (
    <div className="globalDisplay">
      <div className="contact">
        <div className="titreContact">
          <h1>
            Me{" "}
            <span className="spanNameTrace">
              <img src={traitContact} className="traitContact" />
              contacter
            </span>
          </h1>
        </div>
        <div className="contentContactDisplay">
          <div className="contactInfos">
            <p className="textIntro balooT">
              Si vous souhaitez en savoir davantage sur mon parcours, veuillez
              remplir les informations ci-contre et je vous contacterai sous peu
              !
            </p>
            <hr className="separationBarre"></hr>
            <p className="coordonneesContact balooT">
              Vous pouvez également{" "}
              <span className="spanInfosContact">m'appeler</span>
              <br />
              ou{" "}
              <span className="spanInfosContact">me contacter par email</span>:
              <br />
              06 49 53 96 79
              <br />
              andreaealberic@gmail.com
            </p>
          </div>
          <div className="contactForm">
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  );
}
