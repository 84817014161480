import React from "react";
import { NavLink } from "react-router-dom";

export default function DefaultButton({ pageLink, buttonName }) {
  return (
    <NavLink to={pageLink} className="defaultButtonConfig">
      <button>{buttonName}</button>
    </NavLink>
  );
}
