import React from "react";

export default function Experiences({ data }) {
  return (
    <div className="experienceDiv">
      <div className="experienceDate baloo">
        <div className="dateFin">{data.dates.fin}</div>
        <div className="dateDebut">{data.dates.debut}</div>
      </div>
      <div>
        <h5
          className={`titreExperience ${
            data.experiencesPro == "true" ? "backColorBlue" : "backColorPurple"
          }`}
        >
          {data.title}
        </h5>
        <p className="baloo">
          {data.subtitle}
          <span className="balooT">{data.contract}</span>
        </p>

        <p className="balooT experienceDescription">{data.description}</p>
      </div>
    </div>
  );
}
