import React from "react";
import Cards from "../Cards/Cards";
import listProjects from "../../json/projectCards.json";

export default function ListCards() {
  return (
    <div className="blocCardsDisplay">
      <div className="listCardsDisplay">
        {listProjects.map((item) => (
          <Cards key={item.id} card={item} />
        ))}
      </div>
    </div>
  );
}
