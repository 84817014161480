import React, { useState } from "react";
import Toggle from "react-styled-toggle";
import PagePerso from "../../components/PagePerso/PagePerso";
import PagePro from "../../components/PagePro/PagePro";
import DivImgPerso from "../../components/DivImgPerso/DivImgPerso";
import DivImgPro from "../../components/DivImgPro/DivImgPro";
export default function About() {
  const [aboutPro, toggleSwitch] = useState(true);
  const theme = localStorage.getItem("theme");
  const handleToggle = () => {
    toggleSwitch((current) => !current);
  };
  return (
    <div className="globalDisplay">
      <div className="about">
        <div className="divImg">
          {aboutPro ? <DivImgPro theme={theme} /> : <DivImgPerso />}
        </div>
        <div className="toggleSetting">
          <div>
            {aboutPro ? (
              <span className="label">
                Côté <span className="grasPro">pro</span>
              </span>
            ) : (
              <p className="transparentText balooT">Côté pro</p>
            )}
          </div>
          <Toggle
            sliderWidth={27}
            sliderHeight={27}
            width={70}
            height={35}
            translate={35}
            backgroundColorUnchecked="#dfccee"
            backgroundColorChecked="#faccd5"
            onChange={handleToggle}
          />
          <div>
            {aboutPro ? (
              <p className="transparentText balooT">Côté perso</p>
            ) : (
              <span className="label">
                Côté <span className="grasPerso">perso</span>
              </span>
            )}
          </div>
        </div>
      </div>
      {aboutPro ? <PagePro /> : <PagePerso />}
    </div>
  );
}
