import React from "react";
import ListCards from "../../components/ListCards/ListCards";
import traitProjet from "../../assets/img/traceProjets.svg";

export default function Projects() {
  return (
    <div className="globalDisplay">
      <div className="projects">
        <h1>Mes projets</h1>
        <img src={traitProjet} />
      </div>
      <ListCards />
    </div>
  );
}
