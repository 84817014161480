import "./main.css";
import Layout from "./pages/Layout";
import Home from "../src/pages/Home/Home";
import About from "../src/pages/About/About";
import Projects from "./pages/Projects/Projects";
import Contact from "./pages/Contact/Contact";
import PageProject from "./components/PageProject/PageProject";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { useContext } from "react";
import { DarkModeContext } from "./context";

export default function App() {
  const { darkMode } = useContext(DarkModeContext);
  var theme = darkMode ? "dark" : "light";
  return (
    <div data-theme={theme} id="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/accueil" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/projects/:id" element={<PageProject />} />
            <Route path="/contact" element={<Contact />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}
