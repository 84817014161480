import React, { useContext } from "react";
import data from "../../json/aboutPro.json";
import dataFormation from "../../json/aboutFormation.json";
import Experiences from "../experiences/Experiences";
import trace1 from "../../assets/img/pageProTrace1.svg";
import trace2 from "../../assets/img/pageProTrace2.svg";
import trace3 from "../../assets/img/pageProTrace3.svg";
import trace4 from "../../assets/img/pageProTrace4.svg";
import dataCompetencesDarkPicto from "../../json/competences.json";
import SecondaryButton from "../SecondaryButton/SecondaryButton";
import dataCompetencesLightPicto from "../../json/competencesLight.json";
import { DarkModeContext } from "../../context";

export default function PagePro() {
  const { darkMode } = useContext(DarkModeContext);
  const dataCompetences = darkMode
    ? dataCompetencesLightPicto
    : dataCompetencesDarkPicto;
  return (
    <div className="pagePro">
      <div className="experiencePro">
        <div className="experiencesProTitre">
          <h4>
            Expériences{" "}
            <span className="spanNameTrace">
              <img src={trace1} className="traceTitreEP" />
              professionnelles
            </span>
          </h4>
        </div>
        {data.map((item, index) => (
          <div key={index}>
            <Experiences data={item} />
          </div>
        ))}
      </div>
      <div className="diplomes">
        <div className="diplomeFormationTitre">
          <h4>
            Diplômes &{" "}
            <span className="spanNameTrace">
              <img src={trace2} className="traceTitreDiplome" />
              formations
            </span>
          </h4>
        </div>
        <div>
          {dataFormation.map((item, index) => (
            <div key={index}>
              <Experiences data={item} />
            </div>
          ))}
        </div>
        <div className="buttonContactCv">
          <SecondaryButton buttonName="Me contacter" pageLink="/contact" />
          <SecondaryButton
            buttonName="Mon CV"
            pageLink="https://drive.google.com/file/d/13TwpZwjiGBkqhhEBdk2ybCWfny96TqXD/view?usp=drive_link"
            newWindow
          />
        </div>
        <img src={trace4} className="traitTitre4" />
      </div>
      <div className="competencesTechniquesTitre">
        <h4>
          Compétences{" "}
          <span className="spanNameTrace">
            <img src={trace3} className="traceTitreComp" />
            techniques
          </span>
        </h4>
      </div>
      <div className="competencesTechniques">
        <div className="compDev">
          <h5>Développement</h5>
        </div>
        <div className="competencesTechniquesIconesDev">
          {dataCompetences.developpement.map((item, index) => (
            <img src={item} key={index} className="iconeCompetences" />
          ))}
        </div>
        <div className="compGraph">
          <h5>Graphisme</h5>
        </div>
        <div className="competencesTechniquesIconesGraph">
          {dataCompetences.graphisme.map((item, index) => (
            <img src={item} key={index} className="iconeCompetences" />
          ))}
        </div>
      </div>
    </div>
  );
}
