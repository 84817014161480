import React from "react";
import DefaultButton from "../DefaultButton/DefaultButton";
import projectData from "../../json/projectCards.json";
import { useParams } from "react-router-dom";
import traitPageProjet1 from "../../assets/img/tracePageProjet1.svg";
import traitPageProjet2 from "../../assets/img/tracePageProjet2.svg";
import scrollTrace from "../../assets/img/scrollTrace.svg";
import SecondaryButton from "../SecondaryButton/SecondaryButton";

export default function PageProject() {
  const { id } = useParams();
  const boxData = projectData.find((item) => item.id == id);

  if (boxData) {
    const { lienSite, lienGitHub, lienJeu, langages, pictures, imgCouv } =
      boxData.pageProjet;
    const isLienSitePresent = Boolean(lienSite);
    const isLienGitHubPresent = Boolean(lienGitHub);
    const isLienJeuPresent = Boolean(lienJeu);
    const isImgCouv = Boolean(imgCouv);
    const areLangagesPresent = langages && langages.length > 0;
    const arePicturesPresent = pictures && pictures.length > 0;

    return (
      <div className="globalDisplay">
        <div className="pageProject">
          <div className="buttonPos">
            <DefaultButton buttonName="< Retour" pageLink="/projects" />
          </div>
          <div className="titrePageProject">
            <h1>{boxData?.title}</h1>
          </div>
          {isImgCouv && (
            <div className="scrollDisplay">
              <span>
                <img src={scrollTrace} className="scrollTrace" />
                Scroll
              </span>
            </div>
          )}
          {isImgCouv && (
            <div className="imgCouv">
              <img src={boxData.pageProjet.imgCouv} />
            </div>
          )}
          <div className="pageProjectsButton">
            {isLienSitePresent && (
              <SecondaryButton
                buttonName="Aperçu"
                pageLink={lienSite}
                newWindow
              />
            )}
            {isLienGitHubPresent && (
              <SecondaryButton
                buttonName="Lien GitHub"
                pageLink={lienGitHub}
                newWindow
              />
            )}
          </div>
          <div className="descriptionLangagesDisplay">
            <div className="titreTraitDescription">
              <h5>
                <span className="spanNameTrace">
                  <img src={traitPageProjet1} className="traitPageProjet1" />
                  Description
                </span>
              </h5>
            </div>
            <div className="descriptionProjet">
              <p className="balooT">{boxData.pageProjet.description}</p>
            </div>
            <div className="titreTraitLangages">
              {areLangagesPresent && (
                <React.Fragment>
                  <h5>Langages</h5>
                  <img src={traitPageProjet2} />
                </React.Fragment>
              )}
            </div>
            <div className="iconesLangagesPageProjet">
              {areLangagesPresent &&
                langages.map((item, index) => <img src={item} key={index} />)}
            </div>
            <div className="pageProjectsButtonJouer">
              {isLienJeuPresent && (
                <SecondaryButton
                  buttonName="Jouer"
                  pageLink={lienJeu}
                  newWindow
                />
              )}
            </div>
          </div>
          <div className="titreTraitPictures">
            {arePicturesPresent && (
              <React.Fragment>
                <h5>Images</h5>
                <img src={traitPageProjet2} />
              </React.Fragment>
            )}
          </div>
          <div className="picturesPageProjet">
            {arePicturesPresent &&
              pictures.map((item, index) => <img src={item} key={index} />)}
          </div>
        </div>
      </div>
    );
  }
}
