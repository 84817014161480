import * as React from "react";
import trace1 from "../../assets/img/traceMainPage.svg";
import trace2 from "../../assets/img/traceMainPage2.svg";
import SecondaryButton from "../../components/SecondaryButton/SecondaryButton";

export default function Home() {
  return (
    <div className="globalDisplay">
      <div className="home">
        <div className="titrePortfolio">
          <h1>
            Salut, moi c’est{" "}
            <span className="spanNameTrace">
              <img src={trace1} className="traceMainPage" />
              Albéric.
            </span>
          </h1>
        </div>
        <h3 className="sousTitrePortfolio">
          Je suis <span className="dfjs baloo">développeur</span>{" "}
          <span className="dfjs baloo">front-end </span>
          <br />
          basé en Gironde, France.
        </h3>
        <div className="divSecondaryButton">
          <SecondaryButton buttonName="Mes projets" pageLink="/projects" />
          <SecondaryButton buttonName="Contact" pageLink="/contact" />
        </div>
        <img src={trace2} className="traceMainPage2" />
      </div>
    </div>
  );
}
