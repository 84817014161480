import React from "react";

export default function FunFact({ number, text, color }) {
  let colorClass;
  if (color == "blue") {
    colorClass = "colorBlue";
  } else if (color == "purple") {
    colorClass = "colorPurple";
  } else if (color == "pink") {
    colorClass = "colorPink";
  }
  return (
    <div className="blocFunFact">
      <p className={`number baloo ${colorClass}`}>{number}</p>
      <p className="balooT">
        <span className="textZero">{text[0]}</span>
        <span className="textGras baloo">{text[1]}</span>
        <span className="textDeux">{text[2]}</span>
      </p>
    </div>
  );
}
