import React from "react";
import { Link } from "react-router-dom";
import DefaultButtonCards from "../DefaultButtonCards/DefaultButtonCards";

function TypeCard({ card }) {
  return (
    <div className="langTypeCard contentSection">
      <p className={`intituleCard ${card.textColor}`}>&lt;type&gt;</p>
      <span className="spanCardsLangType balooT">{card.type}</span>
      <p className={`intituleCard ${card.textColor}`}>&lt;/type&gt;</p>
    </div>
  );
}
function LangCard({ card }) {
  return (
    <div className="langTypeCard contentSection">
      <p className={`intituleCard ${card.textColor}`}>&lt;langages&gt;</p>
      <span className="spanCardsLangType balooT">{card.langages}</span>
      <p className={`intituleCard ${card.textColor}`}>&lt;/langages&gt;</p>
    </div>
  );
}
export default function Cards({ card }) {
  const langTypeCard =
    card.langages == "" ? <TypeCard card={card} /> : <LangCard card={card} />;
  return (
    <div className="blocCards">
      <Link to={"/projects/" + card.id}>
        <div className={`displayCards ${card.color}`}>
          <div className="cardsScreen">
            <div className="contentCard">
              <div className="contentSection">
                <p className={`intituleCard ${card.textColor}`}>
                  &lt;title&gt;
                </p>
                <h5>{card.title}</h5>
                <p className={`intituleCard ${card.textColor}`}>
                  &lt;/title&gt;
                </p>
              </div>
              <div className="contentSection">{langTypeCard}</div>
              <div className="contentSection">
                <p className={`intituleCard ${card.textColor}`}>
                  &lt;poste&gt;
                </p>
                <span className="spanCardsPoste balooT">{card.poste}</span>
                <p className={`intituleCard ${card.textColor}`}>
                  &lt;/poste&gt;
                </p>
              </div>
              <div className="contentSection">
                <p className={`intituleCard ${card.textColor}`}>
                  &lt;résumé&gt;
                </p>
                <span className="spanCards balooT">{card.resume}</span>
                <p className={`intituleCard ${card.textColor}`}>
                  &lt;/résumé&gt;
                </p>
              </div>
            </div>
          </div>
          <div className="buttonPos">
            <DefaultButtonCards
              buttonName="voir le projet"
              pageLink={"/projects/" + card.id}
            />
          </div>
        </div>
      </Link>
    </div>
  );
}
