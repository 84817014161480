import React, { useState } from "react";

export default function ContactForm() {
  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.firstName && formData.email && formData.message) {
      const mailtoLink = `mailto:andreaealberic@gmail.com?subject=Nouveau%20message%20depuis%20le%20formulaire%20de%20contact&body=Nom%2FPrénom%3A%20${encodeURIComponent(
        formData.firstName
      )}%0D%0AEmail%3A%20${encodeURIComponent(
        formData.email
      )}%0D%0AMessage%3A%20${encodeURIComponent(formData.message)}`;
      window.location.href = mailtoLink;
    } else {
      alert("Veuillez remplir tous les champs obligatoires.");
    }
  };

  return (
    <div className="contactFormDisplay">
      <form onSubmit={handleSubmit} className="contactFormGrid" method="get">
        <label className="nameForm">
          Nom/Prénom
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
          />
        </label>
        <label className="emailForm">
          Email
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </label>
        <label className="messageForm">
          Message
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
          />
        </label>
        <button type="submit" className="sendButton">
          Envoyer
        </button>
      </form>
    </div>
  );
}
