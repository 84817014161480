import React, { useContext } from "react";
import imgPerso from "../../assets/img/cotePerso.svg";
import imgPersoDark from "../../assets/img/cotePersoDark.svg";
import biberon from "../../assets/img/biberon.svg";
import manette from "../../assets/img/manette.svg";
import micro from "../../assets/img/micro.svg";
import masque from "../../assets/img/masque.svg";
import { DarkModeContext } from "../../context";

export default function DivImgPerso() {
  const { darkMode } = useContext(DarkModeContext);
  const renderImages = () => {
    if (!darkMode) {
      return (
        <div className="divImg">
          <img src={imgPerso} className="imgPerso" />
          <img src={biberon} className="biberon" />
          <img src={manette} className="manette" />
          <img src={micro} className="micro" />
          <img src={masque} className="masque" />
        </div>
      );
    } else if (darkMode) {
      return (
        <div className="divImg">
          <img src={imgPersoDark} className="imgPerso" />
          <img src={biberon} className="biberon" />
          <img src={manette} className="manette" />
          <img src={micro} className="micro" />
          <img src={masque} className="masque" />
        </div>
      );
    }
  };
  return <div className="divImg">{renderImages()}</div>;
}
