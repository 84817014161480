import React from "react";
import { NavLink } from "react-router-dom";

export default function SecondaryButton({ pageLink, buttonName, newWindow }) {
  return (
    <NavLink
      to={pageLink}
      target={newWindow ? "_blank" : null}
      className="secondaryButtonConfig"
    >
      <button>{buttonName}</button>
    </NavLink>
  );
}
