import React, { useState, useContext } from "react";
import imgPro from "../../assets/img/cotePro.svg";
import crochets from "../../assets/img/crochets.svg";
import guillemets from "../../assets/img/guillemets.svg";
import accolades from "../../assets/img/accolades.svg";
import etoileTop from "../../assets/img/etoileTop.svg";
import etoile from "../../assets/img/etoile.svg";
import cerclePro from "../../assets/img/cerclePro.svg";
import imgProDark from "../../assets/img/coteProDark.svg";
import crochetsDark from "../../assets/img/crochetsDark.svg";
import guillemetsDark from "../../assets/img/guillemetsDark.svg";
import accoladesDark from "../../assets/img/accoladesDark.svg";
import etoileTopDark from "../../assets/img/etoileTopDark.svg";
import etoileDark from "../../assets/img/etoileDark.svg";
import { DarkModeContext } from "../../context";

export default function DivImgPro() {
  const { darkMode } = useContext(DarkModeContext);
  const renderImages = () => {
    if (!darkMode) {
      return (
        <div className="divImg">
          <img src={imgPro} className="imgPro" />
          <img src={crochets} className="crochets" />
          <img src={guillemets} className="guillemets" />
          <img src={accolades} className="accolades" />
          <img src={etoileTop} className="etoileTop" />
          <img src={etoile} className="etoile" />
          <img src={cerclePro} className="cerclePro" />
        </div>
      );
    } else if (darkMode) {
      return (
        <div className="divImg">
          <img src={imgProDark} className="imgPro" />
          <img src={crochetsDark} className="crochets" />
          <img src={guillemetsDark} className="guillemets" />
          <img src={accoladesDark} className="accolades" />
          <img src={etoileTopDark} className="etoileTop" />
          <img src={etoileDark} className="etoile" />
          <img src={cerclePro} className="cerclePro" />
        </div>
      );
    }
  };
  return <div className="divImg">{renderImages()}</div>;
}
